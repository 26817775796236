<template>
  <div class="row ">
    <div class="col-12">
      <div class="title-nav">
        <h2>{{ $t('Chat') }}</h2>
      </div>
    <div class="search">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" width="28" height="28" stroke-width="1.25"> <path d="M8 9h8"></path> <path d="M8 13h6"></path> <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path> </svg>

        <h2 class="title">
          {{
               this.$i18n.locale == "ar"
              ? 'تستطيع من خلال هذه النافذة التواصل مع ممثل خدمة العملاء بشكل مباشر وسيتم الرد عليك في أقرب وقت ممكن'
              : `Through this window, you can communicate with the customer service representative directly and you will be answered as soon as possible`
          }}
        </h2>
      </div>
    </div>
    </div>
    <div class="correspondence mt-0" style="padding: 20px;">
      <div class="chat-content">
        <div v-if="joined" class=" mb-3" v-for="item in receive_message">
          <div class="row" v-if="item.sent_by_user == 1">
            <div class="col-md-7 d-flex mb-2">
              <div class="logo" style="margin-inline-end: 5px;">
                <img style="border-radius: 50%"  width="32" :src="Avatar" alt="">
              </div>
              <div class="card-white">
                {{ OrderData?.user?.name }}
                <div class="content">
                  {{ item.content }}
                </div>
                <div class="date">
                  {{ formatDate(item.created_at) }}
                </div>
              </div>

            </div>
            <div class="col-md-5"></div>
          </div>
          <div class="row" v-else>
            <div class="col-md-7"></div>
            <div class="col-md-5 d-flex">
              <div class="card-blue">
                {{ $t("customer_service_representative") }}
                <div class="content">
                  {{ item.content }}
                </div>
                <div class="date">
                  {{ formatDate(item.created_at) }}
                </div>
              </div>
              <div class="logo" style="margin-inline-start: 5px;">
                <img style="border-radius: 50%"  width="32" src="@/assets/img/global/dark-logo.png" alt="etmaam's logo">
              </div>
            </div>
          </div>
        </div>
        <div v-else class="w-100 mb-3 text-center">
          <div class="spinner-border text-secondary" role="status">
          </div>
        </div>
      </div>
      <div class="post-correspondence" v-on:keyup.enter="SendMessage">
        <input v-model="message" type="text" name="correspondence" :placeholder="$t('write_a_message')">
        <button class="btn-main" @click="SendMessage">
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0095238 17.5L20 9L0.0095238 0.5L0 7.11111L14.2857 9L0 10.8889L0.0095238 17.5Z"
                  fill="white"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { io } from 'socket.io-client'
import Cookie from 'cookie-universal'

const cookie = Cookie()
const socket = io('https://chat.etmaam.com.sa', {
  extraHeaders: {
    'token': `${cookie.get('CompanyToken')}`, // Include the token in the headers
  },
})
export default {
  data () {
    return {
      joined: false,
      message: '',
      receive_message: [],
      Avatar: cookie.get('Userdata').image
    }
  },
  props: {
    service_provider: Object,
  },
  methods: {
    SendMessage () {
      if (this.message != '') {
        socket.emit('send_message', {
          content: this.message
        })
        this.message = ''
      }
      // console.log(this.message)
    },
    formatDate (dateString) {
      const date = new Date(dateString)

      // Extract time components
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${hours}:${minutes}:${seconds}`
    }
  },
  created () {
    // Chat
    socket.emit('join_chat', {
      id: this.$route.params.id,
      type: 'Package'
    })

  },
  mounted () {
    socket.on('logs', (data) => {
      console.log('Logs:', data)
      if (data == 'You joined the chat.') {
        this.joined = true
        socket.emit('load_messages')
      }
    })
    socket.on('load_messages', (messages) => {
      this.receive_message = messages
    })

    socket.on('receive_message', (message) => {
      const temp = this.receive_message.find(item => item.content === message.content)
      if (!temp) {
        this.receive_message.push(message)
      }
      console.log('New message received:', message)
    })

  }

}
</script>
